.menuCardContainer {
  /* width: 215px; */
  height: 295.487px;
  border-radius: 8px;
  background: #f9f9fb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.menuCardContainer img {
  box-shadow: 1.10256px 4.41026px 8.82051px 3.30769px rgba(255, 144, 0, 0.1);
}
.menuCardBtmContainer {
  padding: 19px 7.89px 14px 16px;
  border-top: 0.3px solid #d6d6d8;
  background: #f9f9fb;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.menuCardMenu {
  position: absolute;
  bottom: -140px;
  height: 100px;
  width: 150px;
  right: 10px;
  border-radius: 8px;
  background: #fefefe;
  box-shadow: 2px 4px 11px 0px rgba(177, 178, 180, 0.25);
  padding: 20px 10px;
  z-index: 10;
}
.menuCardMenu ul li {
  margin-bottom: 20px;
}
.menuGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 32px;
}

.qrCodeCard {
  /* width: 100%; */
  padding: 40px 20px 14px 20px;
  background: #000;
}
.qrCodeAvatar {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  border: 1px solid #f9f9fb;
  margin: auto;
  background: #eee;
}

@media screen and (min-width: 768px) {
  .qrCodeCard {
    max-width: 350px;
    margin: auto;
  }
}
