.gradientBg {
  padding: 40px 20px;
  /* height: 100vh; */
}

.onBoardingSection {
  overflow-y: scroll;
}
.onBoardingCardContainer {
  /* height: 417px; */
  /* width: 100%; */
  border-radius: 50px;
  box-shadow: 4px 4px 20px 0px rgba(28, 29, 30, 0.05);
  background: none;
}

.onBoardingHeader {
  padding: 0 0 40px 0;
}

.onboardingCardImage img {
  object-fit: contain;
  width: 100%;
}
.onboardingCardText {
  padding: 16px 40px;
  text-align: center;
  /* height: 120px; */
}
.onBoardingFlowGrid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  gap: 40px 100px;
  margin: auto;
  width: 100%;
}

.onBoardingInputGroup {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px 24px !important;
  margin-bottom: 16px;
}

.profileSetupContent {
  padding: 42px 20px;
}
.profileSetupLogo {
  padding: 20px 0 0 20px;
}
.profileSetupUploadContainer {
  outline-width: 0.5px;
  outline-style: dashed;
  outline-color: #b1b2b4;
  border-radius: 8px;
  padding: 16px;
  background: #d6d6d8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .profileSetupContent {
    padding: 20px 288px;
  }
  .onBoardingInputGroup {
    grid-template-columns: repeat(2, 1fr);
  }

  .onBoardingCardContainer {
    height: 417px;
    align-self: center;
    justify-self: center;
    width: 323px;
  }
}
