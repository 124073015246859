.authModalContainer,
.authRedirectModalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 56, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.authModalContentContainer,
.authRedirectModalContent {
  background: #fff;
  border: 0.5px #ffdfb6 solid;
  padding: 20px;
  border-radius: 24px 24px 0 0;
  position: relative;
}
.authModalContentHeader button,
.authRedirectModalHeader button {
  background: #f0f1f2;
  border-radius: 70px;
  height: 40px;
  width: 40px;
  border: none;
  cursor: pointer;
}
.authModalFormContainer {
  padding: 0 0 40px 0;
}
.authModalContainerFooter {
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  border-radius: 0 0 24px 24px;
}
.authModalContainerFooter img {
  width: 100%;
  border-radius: 0 0 24px 24px;
}
.authButton {
  border: none;
  color: #fff;
  width: 100%;
}
.termsContainer {
  margin: 40px auto;
}

.authRedirectModalContent {
  border-radius: 24px;
  padding: 0;
}
.authRedirectModalContainer {
  align-items: center;
}
.authRedirectModalHeader {
  padding: 16px 24px;
  border-bottom: 0.5px #ebebeb solid;
}
.authRedirectModalText {
  padding: 16px 24px;
}
.authRedirectModalFooter {
  padding: 16px 24px;
  border-top: 0.5px #ebebeb solid;
}

@media screen and (min-width: 768px) {
  .authModalContainer {
    align-items: center;
  }

  .authModalContentContainer {
    border-radius: 24px;
    padding: 40px;
  }
}
