.heroContainer {
  background-image: url("../images/massHero.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.aboutContainer {
  padding: 100px 20px;
}
.keyFeaturesContainer {
  padding: 0 20px 80px 20px;
}
.keyFeaturesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px 16px;
}
.keyFeaturesCard {
  background: #f9f9fb;
  border: 0.5px solid #d6d6d8;
  border-radius: 16px;
  /* width: 100%; */
}

.keyFeaturesImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.keyFeaturesContentContainer {
  padding: 40px 20px;
}

.getStartedStepsContainer {
  background: #f9f9fb;
  padding: 80px 20px;
}
.stepsListItemContainer {
  display: grid;
  grid-template-columns: 10% 80%;
  gap: 24px;
}

.stepsListNumberContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffa024;
  display: flex;
  justify-content: center;
  align-items: center;
}
.faqContainer {
  padding: 80px 20px;
}

@media screen and (min-width: 768px) {
  .heroContainer {
    padding: 0 80px;
    height: 774px;
  }
  .aboutContainer {
    padding: 100px 188px;
    /* height: 100vh; */
  }
  .keyFeaturesContainer {
    padding: 0 80px 120px 80px;
  }

  .getStartedStepsContainer {
    padding: 80px 137px;
  }
  .stepsListNumberContainer {
    width: 64px;
    height: 64px;
  }
  .faqContainer {
    padding: 120px 122px;
  }
}
