.pb8 {
  padding: 0 0 8px 0;
}
.pb16 {
  padding: 0 0 16px 0;
}

.pb24 {
  padding: 0 0 24px 0;
}
.pb40 {
  padding: 0 0 40px 0;
}
.pb100 {
  padding: 0 0 100px 0;
}
.pVertical16 {
  padding: 16px 0;
}
