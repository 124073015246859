body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

/* address_components */
.css-tlfecz-indicatorContainer {
  display: none !important;
}
.css-1wy0on6 {
  display: none !important;
}

.css-yk16xz-control {
  /* width: 416px; */
  background: #ffffff !important;
  border: 1px solid #ebebeb !important;
  border-radius: 8px !important;
  outline: none !important;
  color: #000;
}

::placeholder {
  font-family: "Nunito";
  /* font-size: 14px !important; */
}
.css-a6opch-control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

/* Hide scrollbars for all elements */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Firefox */
* {
  scrollbar-width: none;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
