.dashboardLayoutContainer {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
}
.dashboardLayoutContentContainer {
  width: 100%;
  margin-left: 0;
}

.topNavContainer {
  background: #181819;
  box-shadow: 4px 4px 8px rgba(17.18, 15.89, 20.4, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  padding: 20px;
  color: "#fff";
}
.avatarContainer {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 0.5px solid #ffa024;
  border-radius: 100px;
}
.sideBarContainer {
  background: #181819;
  box-shadow: 4px 4px 8px rgba(17.18, 15.89, 20.4, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
  height: 100vh;
  z-index: 5;
  padding: 0 24px 100px 24px;
  overflow: scroll;
}
.sideBarHeader {
  position: sticky;
  top: 0;
  background: inherit;
  padding: 20px 0 10px 0;
}
.sideBarContainer ul {
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}
.sideBarContainer li a {
  position: relative;
  gap: 10px;
  color: #fff;
}
.sideBarActiveLink {
  background: #ff9000;
  border-radius: 8px;
  padding: 10px;
}
.sideBarInActiveLink {
  background: inherit;
}
.activeIndicator {
  background: #fff;
  width: 5px;
  height: 16px;
  border-radius: 8px;
  position: absolute;
  right: 10px;
  top: 15px;
}
.sideBarTemplatesHeader {
  background: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  margin: 60px 0 0 0;
  text-align: center;
  padding: 8px;
}
.templatesCardContainer {
  background: #f9f9fb;
  border-radius: 8.82px;
  padding: 20px 40px;
  /* border: 1px solid red; */
  text-align: center;
  max-height: 295px;
  position: relative;
}
.templatesSideCardContainer {
  border-radius: 8.82px;
  padding: 20px 40px;
  text-align: center;
  /* max-height: 295px; */
}
.templatesCardContainer img {
  object-fit: cover;
  /* width: 100%; */
  height: 100%;
  box-shadow: 1.1025640964508057px 4.410256385803223px 8.820512771606445px 3.307692289352417px rgba(255, 144, 0, 0.1);
  border-radius: 8.82px;
  border: 0.28px #b1b2b4 solid;
}
.templatesSideCardContainer img {
  object-fit: contain;
  width: 100%;
  /* height: 100%; */
  box-shadow: 1.1025640964508057px 4.410256385803223px 8.820512771606445px 3.307692289352417px rgba(255, 144, 0, 0.1);
  border-radius: 8.82px;
}
.freeTag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff9111;
  border-radius: 5px;
  padding: 5px;
}
.templatesGridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 32px;
  /* grid-template-columns: repeat(fill, minmax(350px, 1fr)); */
}
.templateEditorContainer {
  /* background: #f0f1f2; */
  padding: 53px 20px;
  overflow-y: scroll;
}
.createMenuBtn {
  border: none;
  background: #ff9000;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .dashboardLayoutContentContainer {
    margin-left: 234px;
  }
  .topNavContainer {
    padding: 20px 80px;
  }
  .sideBarContainer {
    background: #181819;
    width: 232px;
  }
  .templateEditorContainer {
    padding: 53px 261px;
  }
}
