.navContainer {
  padding: 12px 20px;
  position: sticky;
  top: 0;
  background: #fff;
}
.navContainer li {
  margin: 20px;
}
.navContainer button {
  background: inherit;
  border: none;
}
.mobileNav {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: #fff;
  z-index: 5px;
}

.newsLetterContainer {
  padding: 20px;
  background: #010101;
}

.footerContainer {
  background: url("../images/pattern.svg") #181819;
  height: auto;
  background-repeat: no-repeat;
  background-position: top;
  padding: 40px 20px;
}
.divider {
  height: 1px;
  background: #475467;
  margin: 10px 0 20px 0;
}

@media screen and (min-width: 768px) {
  .navContainer {
    padding: 24px 80px;
  }
  .navContainer li {
    margin: 0 44px 0 0;
  }
  .newsLetterContainer {
    padding: 40px 112px;
  }
  .footerContainer {
    background-position: center;
    padding: 64px 112px;
  }
}
