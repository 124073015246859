.customButton {
  border-radius: 8px;
  height: 48px;
  cursor: pointer;
}
.accordion {
  /* border: 1px solid #ccc; */
  margin-bottom: 10px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 1000px; /* Set a maximum height to accommodate the expanded content */
}

.accordion.expanded {
  max-height: none; /* Allow the content to expand to its natural height */
}

.accordion-header {
  cursor: pointer;
  padding: 10px;
}

.accordion-title {
  margin: 0;
}

.accordion-content {
  padding: 10px;
}

.inputContainer {
  margin-bottom: 16px;
  height: auto;
}
.input {
  border: 1px solid #ebebeb;
  /* background-color: inherit; */
  border-radius: 8px;
  padding: 0 16px;
  outline: none;
  /* margin: 8px 0 16px 0; */
  height: 40px;
}
.input input {
  border: none;
  height: 90%;
  outline: none;
  width: 100%;
  /* text-transform: capitalize; */
}
.inputContainer button {
  background: inherit;
  border: none;
}

.custom-dropdown {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 0 16px;
  outline: none;
  background: inherit;
  height: 40px;
  position: relative;
  cursor: pointer;
}
.custom-dropdown input {
  border: none;
  height: 90%;
  outline: none;
  width: 100%;
  cursor: pointer;
}
.custom-dropdown .dropdown-list {
  background: #fff;
  border: 0.5px solid #ebebeb;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  z-index: 20;
  position: relative;
}
.custom-dropdown .dropdown-list li {
  margin: 0 0 16px 0;
  cursor: pointer;
  padding: 10px;
}
.custom-dropdown .dropdown-list li:hover {
  border: 0.5px solid #ebebeb;
  transition: 0.5s ease-in-out all;
}
.caret {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
}

.caret.open {
  border-top: 6px solid #fff;
}

/* Loader  */

.loader {
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 16px;
  margin: auto;
}

.otp-input {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-family: "Nunito";
}
