.templateContainer {
  width: 100%;
}

.template1Header {
  background: #000;
  padding: 44px 41px 14px 41px;
  display: grid;
  grid-template-columns: 100%;
  place-items: center center;
}
.menuLogoContainer {
  height: 64px;
  width: 64px;
  background: #d9d9d9;
  border-radius: 9999px;
  border: 0.5px #f9f9fb dotted;
  display: flex;
  flex-direction: column;
  place-content: center center;
  place-items: center center;
  color: #565758 !important;
  margin-bottom: 17px;
}
.template1Header div:nth-child(2),
div:nth-child(3),
div:nth-child(4) {
  color: #f9f9fb;
  gap: 10px;
  margin-bottom: 10px;
}
.templateContent {
  padding: 24px 20px;
  background: #fff;
}
.addCategoriesButton {
  padding: 10px;
  border-radius: 4px;
  border: 0.5px #38383a dotted;
  color: #181819;
  background: inherit;
  width: 100%;
  margin-bottom: 10px;
}
.categoriesList {
  padding: 24px 0 34px 0;
  gap: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}
.modalBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 56, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.modalContent {
  background: #fff;
  padding: 16px 24px;
  border-radius: 16px;
  min-width: 396px;
}
.cancelBtn {
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #999ca0 solid;
  color: #181819;
  font-size: 14px;
  font-weight: 400;
}
.saveBtn {
  overflow: hidden;
  border-radius: 8px;
  border: 1px dashed #181819;
  background: #f9f9fb;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  max-width: 119px;
  margin: auto;
  color: #000;
  height: 30px;
}
.foodItemCardTypeOne {
  background: #fefefe;
  border-radius: 4px;
  overflow: hidden;
  border: 0.5px #e6e7e9 solid;
  padding: 14px;
  margin-bottom: 16px;
  /* position: re; */
}
.foodItemCardTypeOne img {
  width: 110px;
  height: 64px;
  border-radius: 4px;
}
.foodItemCardTypeOnePrice {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(24, 24, 25, 1);
  color: rgba(255, 144, 0, 1);
  border-radius: 4px;
  margin-top: 10px;
}
.deleteBtn {
  border: 0;
  background: rgba(251, 154, 154, 1);
  color: rgba(247, 56, 56, 1);
  width: 146px;
  margin-top: 10px;
}

.editBtn {
  border: 1px solid rgba(153, 156, 160, 1);
  background: #fff;
  color: #000;
  width: 146px;
  margin-top: 10px;
  /* margin-left: 10px; */
}
.foodItemModalContent {
  border-radius: 8px;
  background: #f9f9fb;
  width: 575px;
  max-width: 575px;
  max-height: 500px;
  overflow: scroll;
}
.foodItemModalContent > header {
  /* background: #000; */
  padding: 15px 20px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #ebebeb;
}
.foodItemFormContainer {
  padding: 16px 67px;
}
.foodItemFormNote {
  padding: 16px;
  margin-bottom: 16px;
  background: #fff;
}
.bgWhite {
  background: #fff;
}

.w-50 {
  width: 100%;
}
.w-10 {
  width: 100px;
}
.foodItemForm {
  padding-top: 16px;
}
.foodItemForm input[value="NGN"] {
  color: #999ca0 !important;
}
.foodItemImageUpload {
  background: white;
  border-radius: 8px;
  border: 0.5px #ebebeb dotted;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0 24px 0;
}
.foodItemModalFooter {
  border-top: 1px solid #b1b2b4;
  padding: 16px;
  display: flex;
  justify-content: flex-end !important;
  gap: 24px;
  align-items: alignCenter;
}
.foodItemsList {
  /* padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 0.5px #e6e7e9 solid; */
  padding: 8px 16px;
  border-radius: 8px;
  background: #f0f1f2;
  border-radius: 8px;
  margin: 0 0 0 24px;
}
.foodCounter {
  background: #000;
  border-radius: 99px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.foodItemsListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .modalBackground {
    align-items: center;
  }
  .templateContainer {
    max-width: 375px;
    margin: 0 auto;
  }
}
