.accountsContainer {
  background: #fff;
  padding: 120px 20px 200px 20px;
  /* height: 100vh; */
  overflow: scroll;
}
.accountsContainer > header {
  margin-bottom: 32px;
}

.userInfoContainer > picture {
  width: 80px;
  height: 80px;
  border-radius: 125px;
  border: 1px #ff9000 solid;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userInfoContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.userInfoContainer .editButton {
  background: inherit;
  border: 1px solid #999ca0;
}
.userInfoContainer > p {
  margin-bottom: 8px;
  margin-top: 24px;
}
.userInfoContainer > ul li {
  margin-bottom: 8px;
  gap: 10px;
}
.userOverviewContainer {
  padding: 40px 0;
  margin-top: 40px;
  border-top: 0.25px #b1b2b4 solid;
}
.userOverviewCard {
  padding: 24px 32px 24px 24px;
  background: #f9f9fb;
  border-radius: 16px;
  width: 100%;
}
.userOverviewCard > ul li {
  gap: 8px;
}
.userOverviewCard > ul li div {
  padding: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f1f2;
  border-radius: 100px;
}

.userTransactionContainer {
  padding: 40px 0;
  margin-top: 40px;
  overflow: scroll;
  height: 100;
}
#userTransactionsTable {
  background: #f9f9fb;
  border-radius: 16px;
  padding: 24px;
  min-width: 640px;
}

#userTransactionsTable tbody {
  margin: 10px 0 !important;
}
.mobileTransactionsCard {
  background: #f9f9fb;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 40px 20px;
}
.profileDetailsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}

@media screen and (min-width: 768px) {
  .accountsContainer {
    padding: 100px 80px;
  }
  .userOverviewCard {
    width: 320px;
  }
  .profileDetailsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
